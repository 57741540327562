// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-about-page-template-js": () => import("./../../../src/templates/about-page-template.js" /* webpackChunkName: "component---src-templates-about-page-template-js" */),
  "component---src-templates-article-page-template-js": () => import("./../../../src/templates/article-page-template.js" /* webpackChunkName: "component---src-templates-article-page-template-js" */),
  "component---src-templates-articles-tags-template-js": () => import("./../../../src/templates/articles-tags-template.js" /* webpackChunkName: "component---src-templates-articles-tags-template-js" */),
  "component---src-templates-project-page-template-js": () => import("./../../../src/templates/project-page-template.js" /* webpackChunkName: "component---src-templates-project-page-template-js" */)
}

